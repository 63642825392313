<template>
  <div class="TypeImages">
    <span class="Title">{{ type.getTitle({ details: true }) }}</span>

    <div class="ImageList">
      <div
        v-for="image in type.images"
        :key="image.base.path"
        class="ImageWrap">
        <div class="TopPadding"/>
        <div class="InnerImageWrap">
          <MultiImage
            :image="image"
            transformation="square_360" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MultiImage from '@/components/Images/MultiImage'

export default {
  name: 'TypeImages',
  props: {
    type: {
      type: Object,
      required: true,
    },
  },
  components: {
    MultiImage,
  },
}
</script>

<style lang="stylus" scoped>
  .TypeImages
    span
      display block

  .Title
    modalTitle()

  .ImageList
    display flex
    flex-wrap wrap
    justify-content space-between

  .ImageWrap
    position relative
    width 100%
    margin-bottom 5px
    .TopPadding
      padding-top 100%
    .InnerImageWrap
      position absolute
      top 0
      left 0
      box(100%)
</style>
