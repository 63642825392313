<template>
  <div class="SelectAssessment">
    <span class="Title">{{ mixWB('WHAT_IS_YOUR_ASSESSMENT') }}</span>

    <div
      v-for="(option, index) in assessOptions"
      :key="option.id"
      class="ButtonWrap">
      <Button
        :text="option.text"
        :outlined="index === 0"
        :isDisabled="getDisabledStatus(option.id)"
        @button-click="onButtonClick(option.id)" />
    </div>
  </div>
</template>

<script>
import Button from '@/components/Buttons/Button'

export default {
  name: 'SelectAssessment',
  props: {
    type: {
      type: Object,
      required: true,
    },
    kindOfSample: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      assessOptions: [
        { id: '', text: this.mixWB('NOTHING') },
        {
          id: 'skip-asbest',
          text: this.mixGetSampleStatusWordbook('skip-asbest'),
        },
        {
          id: 'skip-hazardous-waste',
          text: this.mixGetSampleStatusWordbook('skip-hazardous-waste'),
        },
        {
          id: 'skip-hazardous-asbestos-waste',
          text: this.mixGetSampleStatusWordbook('skip-hazardous-asbestos-waste'),
        },
      ],
    }
  },
  methods: {
    getDisabledStatus(optionID) {
      const sampleIDs = this.kindOfSample === 'material'
        ? this.type.getMaterialSampleList({ onlyIDs: true })
        : this.type.getCoatingSampleList({ onlyIDs: true })

      if (optionID === 'skip-asbest') {
        return sampleIDs.length > 1 || !sampleIDs.includes('ST-1')
      }
      if (optionID === 'skip-hazardous-waste') {
        return sampleIDs.includes('ST-1')
      }
      if (optionID === 'skip-hazardous-asbestos-waste') {
        return sampleIDs.length < 2 || !sampleIDs.includes('ST-1')
      }
      return false
    },
    onButtonClick(value) {
      this.$emit('pass-to-parent', { name: 'assess-select', value })
    },
  },
  components: {
    Button,
  },

}
</script>

<style lang="stylus" scoped>
  .SelectAssessment
    span
      display block

  .Title
    modalTitle()

  .ButtonWrap
    margin-bottom 10px
</style>
