<template>
  <div class="SelectEquivalent">
    <span class="Title">{{ mixWB('CHOOSE_A_FINISHED_SAMPLE') }}</span>

    <span class="InfoText">
      {{ mixWB('SAME_MATERIAL_AND_ANALYSES_TEXT') }}
    </span>

    <div class="ButtonWrap">
      <Button
        :text="mixWB('NO_SAMPLE')"
        color="grey"
        outlined
        @button-click="onSampleClick('')" />
      <Select
        v-if="screeningRelatedScreenings.length"
        name="projects"
        :options="projectOptions"
        :selectedID="selectedScreeningID"
        hasShadow
        backgroundColor="white"
        @change="onSelectChange"/>
    </div>

    <div
      class="SampleWrap"
      v-for="sampleItem in sampleList"
      :key="sampleItem.id"
      @click="onSampleClick(sampleItem.sample.id)">
      <div class="ImageWrap">
        <MultiImage
          :image="sampleItem.type.images[0]"
          transformation="square_80" />
        <div class="Number">
          <span v-if="sampleItem.sample.getNumber()">{{ sampleItem.sample.getNumber() }}</span>
        </div>
        <div
          v-if="sampleItem.sample.kindOfSample === 'coating'"
          class="KindOfIcon">
          <BrushIcon />
        </div>
        <div
          v-if="sampleItem.sample.kindOfSample === 'dust'"
          class="KindOfIcon">
          <TapeIcon />
        </div>
      </div>
      <div class="InfoWrap">
        <span>{{ sampleItem.type.getTitle({ category: true, details: true }) }}</span>
        <span>{{ sampleItem.type.getUnitsList({ unitsList: sampleItem.units }) }}</span>
        <span v-if="sampleItem.sample.kindOfSample === 'material'">
          {{ sampleItem.type.getMaterialSampleList() }}</span>
        <span v-if="sampleItem.sample.kindOfSample === 'coating'">
          {{ sampleItem.type.getCoatingSampleList() }}</span>
        <span v-if="sampleItem.sample.kindOfSample === 'dust'">
          {{ sampleItem.type.getDustSampleList() }}</span>
        <span v-if="sampleItem.type.comments">
          {{ mixWB('NOTE') }}: {{ sampleItem.type.comments }}</span>
      </div>
    </div>

    <div
      v-if="!sampleList.length"
      class="NoListText">
      <p>{{ mixWB('NO_SAMPLES_TO_SHOW') }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isEqual, sortBy } from 'lodash-es'
import Button from '@/components/Buttons/Button'
import Select from '@/components/FormElements/Select.vue'
import MultiImage from '@/components/Images/MultiImage.vue'
import BrushIcon from '@/assets/svg/brush.svg?inline'
import TapeIcon from '@/assets/svg/tape.svg?inline'

export default {
  name: 'SelectEquivalent',
  props: {
    sample: {
      type: Object,
      required: true,
    },
    selectedID: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedScreeningID: null,
    }
  },
  computed: {
    ...mapGetters([
      'screeningSamples',
      'screeningTypes',
      'currentScreeningData',
      'screeningRelatedScreenings',
      'selectedUnits',
    ]),
    projectOptions() {
      const options = []

      // Add current project
      options.push({
        id: this.currentScreeningData.id,
        text: this.mixWB('THIS_PROJECT'),
      })

      // Add related screenings
      this.screeningRelatedScreenings.forEach((screening) => {
        if (!screening.data.getCaseNumber()) {
          return
        }

        const buildings = screening.data.getBuildingNames().join(', ')

        options.push({
          id: screening.data.id,
          text: `${
            screening.data.getCaseNumber()
          } - ${
            screening.data.address.address
          }, ${
            screening.data.address.postalCode
          } ${
            screening.data.address.city
          } ${
            buildings ? `(${ buildings })` : ''
          }`,
        })
      })

      return options
    },
    screeningToUse() {
      const screeningToUse = {
        types: [],
        samples: [],
        units: [],
      }

      if (this.selectedScreeningID === this.currentScreeningData.id) {
        screeningToUse.types = this.screeningTypes
        screeningToUse.samples = this.screeningSamples
        screeningToUse.units = this.selectedUnits
        return screeningToUse
      }

      const relatedScreening = this.screeningRelatedScreenings.find(
        (x) => x.data.id === this.selectedScreeningID,
      )

      if (relatedScreening) {
        screeningToUse.types = relatedScreening.types
        screeningToUse.samples = relatedScreening.samples
        screeningToUse.units = relatedScreening.units
      }

      return screeningToUse
    },
    sampleList() {
      const currentRequiredSampleIDs = this.sample.getSampleIDs({
        onlyIDs: true,
        onlyAutoSamples: true,
      }).sort()
      const currentSampleIDs = this.sample.getSampleIDs({ onlyIDs: true }).sort()
      const currentType = this.screeningTypes.find((x) => x.id === this.sample.typeID)
      const currentResourceType = currentType.getResourceType()

      const sampleList = this.screeningToUse.samples.reduce((prev, sample) => {
        // Don't show same sample you are on
        if (sample.sampleNumber === this.sample.sampleNumber) {
          return prev
        }

        // Don't show non-taken samples
        if (!sample.sampleNumber) {
          return prev
        }

        // Don't show samples of differnt kinds
        if (sample.kindOfSample !== this.sample.kindOfSample) {
          return prev
        }

        // Don't show samples based on different types of materials
        const type = this.screeningToUse.types.find((x) => x.id === sample.typeID)
        const resourceType = type.getResourceType()

        // - For custom types
        if (
          currentType.type.customTypeName
          && currentType.type.customTypeName === type.type.customTypeName
        ) {
          // Allow this
        }
        // - For coating
        else if (this.sample.kindOfSample === 'coating' || this.sample.kindOfSample === 'dust') {
          // Allow this
        }
        // - For non-custom types
        else if (
          !currentResourceType || !resourceType || currentResourceType?.id !== resourceType?.id
        ) {
          return prev
        }

        // Don't show samples with different required samples
        const requiredSampleIDs = sample.getSampleIDs({
          onlyIDs: true,
          onlyAutoSamples: true,
        }).sort()

        if (!isEqual(currentRequiredSampleIDs, requiredSampleIDs)) {
          return prev
        }

        // Don't show samples with different analyses
        const sampleIDs = sample.getSampleIDs({ onlyIDs: true }).sort()
        if (!isEqual(currentSampleIDs, sampleIDs)) {
          return prev
        }

        prev.push({
          sample,
          type,
          units: this.screeningToUse.units,
        })

        return prev
      }, [])

      return sortBy(sampleList, ['sample.sampleNumber'])
    },
  },
  methods: {
    onSampleClick(value) {
      const otherScreeningID = this.selectedScreeningID === this.currentScreeningData.id
        ? ''
        : this.selectedScreeningID

      this.$emit('pass-to-parent', {
        name: 'equivalent-select',
        value: {
          equivalentSampleID: value,
          otherScreeningID,
        },
      })
    },
    onSelectChange({ value }) {
      this.selectedScreeningID = value
    },
  },
  components: {
    Button,
    Select,
    MultiImage,
    BrushIcon,
    TapeIcon,
  },
  created() {
    this.selectedScreeningID = this.currentScreeningData.id
  },
}
</script>

<style lang="stylus" scoped>
  .SelectEquivalent
    span
      display block

  .Title
    modalTitle()

  .InfoText
    text-align center
    margin-bottom 20px

  .ButtonWrap
    margin-bottom 20px

  .SampleWrap
    margin-bottom 10px
    padding 5px
    background-color $color_grey_lightest
    box-shadow $box_shadow_1
    display flex
    cursor pointer
    .ImageWrap
      position relative
      flex-shrink 0
      box(60px)
      cursor pointer
      margin-right 10px
      span
        text-align center
        line-height 1
        display block
        font-size 0.75rem
        font-weight bold
      .Number
        position absolute
        bottom 0
        width 100%
        flex-shrink 0
        margin-right 10px
        flex-direction column
        flex-center-children()
        padding 2.5px
        background-color rgba($color_primary, 0.7)
      .KindOfIcon
        position absolute
        top 0
        right 0
        box(20px)
        padding 2.5px
        background-color rgba($color_blue_dark, 0.7)
        svg
          fill #fff
    .InfoWrap
      flex-grow 2
      span
        font-size 0.665rem
        &:first-child
          font-size 0.75rem
          text-transform uppercase
          font-weight bold

  .NoListText
    p
      text-align center
</style>
