var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"SampleItemMenu",class:{
    'NotDone': _vm.sample.status === 'not-done',
    'StatusDone': _vm.sample.status === 'done',
    'StatusEquivalent': _vm.sample.status === 'equivalent',
    'StatusExistingResult': _vm.sample.status === 'existing-result',
    'StatusSkipped': _vm.sample.status.includes('skip-'),
    'StatusPostponed': _vm.sample.status.includes('postpone-sample'),
  },on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('div',{staticClass:"Button",on:{"click":_vm.onButtonClick}},[(_vm.sample.status !== 'not-done')?_c('CheckIcon'):_c('Dots')],1),(_vm.isShowing)?_c('div',{staticClass:"BackDrop",on:{"click":_vm.onBackDropClick}}):_vm._e(),(_vm.isShowing)?_c('div',{staticClass:"MenuWrap"},[_c('DotMenuItem',{attrs:{"text":((_vm.mixWB('SAME_AS')) + " ..."),"itemID":"equivalent"},on:{"dot-item-click":_vm.onDotItemClick}}),_c('DotMenuItem',{attrs:{"text":("" + (_vm.mixWB('ASSESS'))),"itemID":"assess","isDisabled":_vm.sample.kindOfSample === 'dust'},on:{"dot-item-click":_vm.onDotItemClick}}),_c('DotMenuItem',{attrs:{"text":("" + (_vm.mixWB('POSTPONE_SAMPLE'))),"itemID":"postpone"},on:{"dot-item-click":_vm.onDotItemClick}}),_c('DotMenuItem',{attrs:{"text":("" + (_vm.mixWB('ENTER_RESULT'))),"isDisabled":_vm.isEnterResultOptionDisabled,"itemID":"enter-result"},on:{"dot-item-click":_vm.onDotItemClick}}),_c('DotMenuItem',{attrs:{"text":("" + (_vm.mixWB('SAMPLE_MARK_FLOOR_PLAN'))),"isDisabled":!_vm.canMarkOnFLoorPlan,"itemID":"set-marker-floorplan"},on:{"dot-item-click":_vm.onDotItemClick}}),_c('DotSplitLine'),_c('DotMenuItem',{attrs:{"text":_vm.mixWB('ADD_ANALYSES'),"itemID":"add-samples","isDisabled":_vm.isAddManualSamplesDisabled},on:{"dot-item-click":_vm.onDotItemClick}}),_c('DotSplitLine'),_c('DotMenuItem',{attrs:{"text":_vm.mixWB('RESET'),"itemID":"reset","color":"red"},on:{"dot-item-click":_vm.onDotItemClick}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }