<template>
  <div
    class="SampleItem"
    :class="{
      'StatusDone': sample.status === 'done',
      'StatusEquivalent': sample.status === 'equivalent',
      'StatusSkipped': sample.status.includes('skip-'),
      'StatusPostponed': sample.status.includes('postpone-sample'),
      'StatusExistingResult': sample.status === 'existing-result',
      'AutoAssessment': sample.isAutoAssessment,
    }">
    <!-- Image -->
    <div
      class="ImageWrap"
      @click="onImageWrapClick">
      <MultiImage
        :image="type.images[0]"
        transformation="square_80" />
      <div
        v-if="sample.getNumber()"
        class="Number">
        <span>{{ sample.getNumber() }}</span>
      </div>
      <div
        v-if="sample.kindOfSample === 'coating'"
        class="KindOfIcon">
        <BrushIcon />
      </div>
      <div
        v-if="sample.kindOfSample === 'dust'"
        class="KindOfIcon">
        <TapeIcon />
      </div>
    </div>

    <!-- Info -->
    <div class="InfoWrap">
      <span class="Title">{{ type.getTitle({details: true}) }}</span>
      <div class="Row">
        <div class="IconWrap">
          <HouseIcon />
        </div>
        <span>{{ type.getUnitsList() }}</span>
      </div>
      <div
        class="Row"
        v-if="type.getPlacesList()">
        <div class="IconWrap SmallerIcon">
          <RoomsIcon />
        </div>
        <span>{{ type.getPlacesList() }}</span>
      </div>
      <div
        class="Row"
        v-if="sample.kindOfSample === 'material' && getMaterialSamplesText()">
        <div class="IconWrap SmallerIcon">
          <VialIcon />
        </div>
        <span v-html="getMaterialSamplesText()"/>
      </div>
      <div
        class="Row"
        v-if="sample.kindOfSample === 'coating' && getCoatingSamplesText()">
        <div class="IconWrap SmallerIcon">
          <VialIcon />
        </div>
        <span v-html="getCoatingSamplesText()"/>
      </div>
      <div
        class="Row"
        v-if="sample.kindOfSample === 'dust' && getDustSamplesText()">
        <div class="IconWrap SmallerIcon">
          <VialIcon />
        </div>
        <span v-html="getDustSamplesText()"/>
      </div>
      <div
        class="Row"
        v-if="sample.status.includes('skip-') || sample.isAutoAssessment">
        <div class="IconWrap SmallerIcon">
          <WarningIcon />
        </div>
        <span
          class="AssessText">
          {{ mixWB('ASSESSED') }}: {{ mixGetSampleStatusWordbook(sample.status) }}
        </span>
      </div>
      <div
        class="Row"
        v-if="sample.status.includes('postpone-sample')">
        <div class="IconWrap SmallerIcon">
          <WarningIcon />
        </div>
        <span
          class="PostponeText">
          {{ mixWB('SAMPLE_POSTPONED') }}
        </span>
      </div>
      <div
        class="Row"
        v-if="sample.status === 'existing-result'">
        <div class="IconWrap SmallerIcon">
          <WarningIcon />
        </div>
        <span
          class="AssessText">
          {{ mixWB('EXISTING_SAMPLE') }}: {{ existingResultText }}
        </span>
      </div>
      <div
        class="Row"
        v-if="equivalentForText">
        <div class="IconWrap SmallerIcon"/>
        <span>
          {{ mixWB('REPRESENTATIVE_OF') }}: {{ equivalentForText }}
        </span>
      </div>
      <div
        class="Row"
        v-if="internalEquivalentText">
        <div class="IconWrap SmallerIcon"/>
        <span>
          {{ internalEquivalentText }}
        </span>
      </div>
      <div
        class="Row"
        v-if="externalEquivalentText">
        <div class="IconWrap SmallerIcon"/>
        <span>
          {{ externalEquivalentText }}
        </span>
      </div>
    </div>

    <!-- Right side -->
    <template v-if="!sample.isAutoAssessment">
      <Button
        v-if="sample.images.close && sample.images.away && !sample.floorPlanUnit"
        class="FloorPlanButton"
        :text="mixWB('FLOOR_PLAN')"
        outlined
        size="small"
        @button-click="onOpenFloorPlanSelect" />
      <div
        class="UploadOptionsWrap"
        v-else>
        <!-- Close image -->
        <div
          v-if="['not-done', 'done'].includes(sample.status)"
          class="UploadImageWrap">
          <ImageUploadButton
            v-show="!sample.images.close"
            type="close"
            :autoOpenTrigger="autoOpenCloseImage"
            @image-selected="onNewFile" />
          <div
            v-if="sample.images.close"
            class="ImageThumb">
            <MultiImage
              :image="sample.images.close"
              transformation="square_80"
              loadingSize="tiny"
              preview
              enableReupload
              @reupload="onImageReupload('close')" />
          </div>
          <div class="Label">
            <span>{{ mixWB('CLOSE_UP') }}</span>
          </div>
        </div>

        <!-- Away image -->
        <div
          v-if="['not-done', 'done'].includes(sample.status)"
          class="UploadImageWrap">
          <ImageUploadButton
            v-show="!sample.images.away"
            type="away"
            :autoOpenTrigger="autoOpenAwayImage"
            @image-selected="onNewFile" />
          <div
            v-if="sample.images.away"
            class="ImageThumb">
            <MultiImage
              :image="sample.images.away"
              transformation="square_80"
              loadingSize="tiny"
              preview
              enableReupload
              @reupload="onImageReupload('away')" />
          </div>
          <div class="Label">
            <span>{{ mixWB('AWAY') }}</span>
          </div>
        </div>
      </div>
      <div class="MenuWrap">
        <SampleItemMenu
          :sample="sample"
          :type="type"
          @option-click="onMenuItemClick" />
      </div>

    </template>

    <!-- Dialog -->
    <Dialog
      :isShowing="showDialog"
      :useComponent="dialogComponent"
      :componentProps="dialogProps"
      :size="dialogSize"
      :easyClose="false"
      @assess-select="onAssessSelect"
      @result-select="onResultSelect"
      @equivalent-select="onEquivalentSelect"
      @floor-plan-select="onFloorPlanSelect"
      @close="showDialog = false" />
  </div>
</template>

<script>
import HouseIcon from '@/assets/svg/house.svg?inline'
import RoomsIcon from '@/assets/svg/rooms.svg?inline'
import VialIcon from '@/assets/svg/vial.svg?inline'
import WarningIcon from '@/assets/svg/warning.svg?inline'
import MultiImage from '@/components/Images/MultiImage.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import TypeImages from '@/components/SampleItems/Dialog/TypeImages.vue'
import SampleItemMenu from '@/components/SampleItems/SampleItemMenu.vue'
import ImageUploadButton from '@/components/Buttons/ImageUploadButton.vue'
import SelectAssessment from '@/components/SampleItems/Dialog/SelectAssessment.vue'
import SelectResult from '@/components/SampleItems/Dialog/SelectResult.vue'
import SelectEquivalent from '@/components/SampleItems/Dialog/SelectEquivalent.vue'
import SelectFloorPlan from '@/components/SampleItems/Dialog/SelectFloorPlan.vue'
import NoficationModal from '@/components/Modals/NoficationModal'
import AddSamplesModal from '@/components/Modals/AddSamplesModal/AddSamplesModal.vue'
import { mapGetters } from 'vuex'
import { minImageSize, prepareImage } from '@/globals/javascript/_util/images'
import BrushIcon from '@/assets/svg/brush.svg?inline'
import TapeIcon from '@/assets/svg/tape.svg?inline'
import Button from '@/components/Buttons/Button.vue'

export default {
  name: 'SampleItem',
  props: {
    type: {
      type: Object,
      required: true,
    },
    sample: {
      type: Object,
      required: true,
    },
    sampleIndex: {
      type: Number,
      required: true,
    },
    nextSampleNumber: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      minImageSize,
      prepareImage,
      autoOpenCloseImage: false,
      autoOpenAwayImage: false,
      images: {
        close: null,
        away: null,
      },
      // Dialog
      showDialog: false,
      dialogComponent: {},
      dialogProps: {
        showAllSampleTypes: false,
        type: null,
        kindOfSample: '',
        selectedID: '',
        sample: null,
        nextSampleNumber: '',
        texts: [],
      },
      dialogSize: '',
    }
  },
  computed: {
    ...mapGetters([
      'imageUploadFolder',
      'screeningSamples',
      'workDescriptionCasesAsArray',
    ]),
    existingResultText() {
      const item = this.workDescriptionCasesAsArray.find(
        (x) => x.id === this.sample.existingResultWDCID,
      )

      if (!item) {
        return ''
      }

      return this.mixWB(item.translation)
    },
    equivalentForText() {
      if (!this.sample.sampleNumber) {
        return ''
      }

      const equivalentSamples = this.screeningSamples.reduce((prev, otherSample) => {
        if (
          !otherSample.otherScreeningID
          && otherSample.equivalentSampleNumber === this.sample.sampleNumber
        ) {
          prev.push(otherSample.equivalentNumber)
        }

        return prev
      }, [])

      return equivalentSamples.sort().map((x) => `T${ x }`).join(', ')
    },
    internalEquivalentText() {
      if (!this.sample.equivalentNumber || this.sample.otherScreeningID) {
        return ''
      }

      return this.mixWB('REPRESENTED_BY_X', [`P${ this.sample.equivalentSampleNumber }`])
    },
    externalEquivalentText() {
      if (!this.sample.equivalentNumber || !this.sample.otherScreeningID) {
        return ''
      }

      return this.mixWB(
        'REPRESENTED_BY_X_FROM_PROJECT_Y',
        [
          `P${ this.sample.equivalentSampleNumber }`,
          this.sample.getRelatedScreeningCaseNumber(),
        ],
      )
    },
  },
  methods: {
    getBasePath() {
      return `${ this.imageUploadFolder }Samples/`
    },
    getMaterialSamplesText() {
      const materialSampleItems = this.type.getMaterialSampleList({ asArray: true })

      return materialSampleItems.reduce((prev, item) => {
        if (item.isManual) {
          if (!prev) {
            prev = `<b>${ item.title }</b>`
          }
          else {
            prev += `, <b>${ item.title }</b>`
          }
        }
        else if (!prev) {
          prev = item.title
        }
        else {
          prev += `, ${ item.title }`
        }
        return prev
      }, '')
    },
    getCoatingSamplesText() {
      const coatingSampleItems = this.type.getCoatingSampleList({ asArray: true })

      return coatingSampleItems.reduce((prev, item) => {
        if (item.isManual) {
          if (!prev) {
            prev = `<b>${ item.title }</b>`
          }
          else {
            prev += `, <b>${ item.title }</b>`
          }
        }
        else if (!prev) {
          prev = item.title
        }
        else {
          prev += `, ${ item.title }`
        }
        return prev
      }, '')
    },
    getDustSamplesText() {
      const dustSampleItems = this.type.getDustSampleList({ asArray: true })

      return dustSampleItems.reduce((prev, item) => {
        if (!prev) {
          prev = `<b>${ item.title }</b>`
        }
        else {
          prev += `, <b>${ item.title }</b>`
        }
        return prev
      }, '')
    },
    onNewFile({ file, type }) {
      // Create image object
      const imageObject = this.prepareImage({
        basePath: this.getBasePath(),
        file,
        postFix: type,
        minSize: this.minImageSize,
      })

      // Save image sizes for upload
      this.$store.dispatch('uploadImage', {
        imageObject,
        file,
        place: `${ this.mixWB('SAMPLES_2') }: ${ this.type.getTitle({ details: true }) }`,
        makeSquared: true,
      })

      // Save image
      this.$emit('new-image-selected', {
        sample: this.sample,
        imageData: imageObject,
        type,
      })

      if (type === 'away' && !this.sample.floorPlanUnit) {
        this.onOpenFloorPlanSelect()
      }
    },
    onOpenFloorPlanSelect() {
      this.dialogProps.type = this.type
      this.dialogProps.sample = this.sample
      this.dialogProps.nextSampleNumber = this.nextSampleNumber
      this.dialogComponent = SelectFloorPlan
      this.dialogSize = 'fullScreen'
      this.showDialog = true
    },
    onImageReupload(type) {
      requestAnimationFrame(() => {
        if (type === 'close') {
          this.autoOpenCloseImage = true
        }

        if (type === 'away') {
          this.autoOpenAwayImage = true
        }

        requestAnimationFrame(() => {
          this.autoOpenCloseImage = false
          this.autoOpenAwayImage = false
        })
      })
    },
    onMenuItemClick(status) {
      // Check for assesss
      if (status === 'assess') {
        this.dialogProps.type = this.type
        this.dialogProps.kindOfSample = this.sample.kindOfSample
        this.dialogComponent = SelectAssessment
        this.dialogSize = 'small'
        this.showDialog = true
        return
      }

      // Check for postpone
      if (status === 'postpone') {
        this.onPostponeClick()
        return
      }

      // Check for equivalent
      if (status === 'equivalent') {
        this.dialogProps.selectedID = this.sample.equivalentSampleID || ''
        this.dialogProps.sample = this.sample
        this.dialogComponent = SelectEquivalent
        this.dialogSize = 'small'
        this.showDialog = true
        return
      }

      // Enter result
      if (status === 'enter-result') {
        this.dialogComponent = SelectResult
        this.dialogProps.sample = this.sample
        this.dialogSize = 'small'
        this.showDialog = true
        return
      }

      if (status === 'set-marker-floorplan') {
        this.onOpenFloorPlanSelect()
        return
      }

      // Add / remove analyses
      if (status === 'add-samples') {
        if (this.sample.equivalentSampleNumber) {
          this.dialogComponent = NoficationModal
          if (this.sample.otherScreeningID) {
            this.dialogProps.texts = [
              this.mixWB(
                'SAMPLE_REPRESENTED_BY_X_FROM_PROJECT_Y',
                [`P${ this.sample.equivalentSampleNumber }`, this.sample.getRelatedScreeningCaseNumber()],
              ),
            ]
          }
          else {
            this.dialogProps.texts = [
              this.mixWB(
                'THIS_SAMPLE_IS_REPRESENTED_BY_X',
                [`P${ this.sample.equivalentSampleNumber }`],
              ),
            ]
          }
          this.dialogProps.texts.push(
            this.mixWB('MAKE_CHANGES_ON_REPRESENTATIVE_SAMPLE_TEXT'),
          )
          this.dialogSize = 'small'
          this.showDialog = true
          return
        }
        this.dialogProps.type = this.type
        this.dialogProps.kindOfSample = this.sample.kindOfSample
        this.dialogComponent = AddSamplesModal
        this.dialogSize = 'small'
        this.showDialog = true
        return
      }

      if (status === 'reset') {
        const answer = window.confirm(this.mixWB('ARE_YOU_SURE'))
        if (!answer) {
          return
        }
        this.resetSample()
      }
    },
    onImageWrapClick() {
      this.dialogProps.type = this.type
      this.dialogComponent = TypeImages
      this.dialogSize = 'small'
      this.showDialog = true
    },
    onAssessSelect(status) {
      this.showDialog = false

      if (this.sample.sampleNumber) {
        const answer = window.confirm(`${
          this.mixWB('THIS_WILL_RESET_ITS_SAMPLE_NUMBER')
        }. ${
          this.mixWB('ARE_YOU_SURE')
        }`)
        if (!answer) {
          return
        }

        this.resetSample()
      }

      this.$emit('new-state-selected', {
        sample: this.sample,
        status: status || 'not-done',
      })
    },
    onPostponeClick() {
      this.$emit('new-state-selected', {
        sample: this.sample,
        status: 'postpone-sample',
      })
    },
    onResultSelect({ wdcID, explanation }) {
      this.showDialog = false

      this.$emit('new-state-selected', {
        sample: this.sample,
        status: 'existing-result',
        wdcID,
        explanation,
      })
    },
    onEquivalentSelect({ equivalentSampleID, otherScreeningID }) {
      this.showDialog = false

      if (this.sample.sampleNumber) {
        const answer = window.confirm(`${
          this.mixWB('THIS_WILL_RESET_ITS_SAMPLE_NUMBER')
        }. ${
          this.mixWB('ARE_YOU_SURE')
        }`)
        if (!answer) {
          return
        }

        this.resetSample()
      }

      this.$emit('new-state-selected', {
        sample: this.sample,
        status: equivalentSampleID ? 'equivalent' : 'not-done',
        equivalentSampleID,
        otherScreeningID,
      })
    },
    onFloorPlanSelect({ floorPlanPosition, floorPlanUnit }) {
      this.showDialog = false
      this.$emit('floor-plan-selected', {
        sample: this.sample,
        floorPlanPosition,
        floorPlanUnit,
      })
    },
    resetSample() {
      this.$store.dispatch('deleteScreeningSample', this.sample)
    },
  },
  components: {
    BrushIcon,
    TapeIcon,
    HouseIcon,
    RoomsIcon,
    VialIcon,
    WarningIcon,
    MultiImage,
    SampleItemMenu,
    Dialog,
    ImageUploadButton,
    Button,
  },
}
</script>

<style lang="stylus" scoped>
  .SampleItem
    position relative
    background-color $color_grey_lightest
    padding 5px
    margin-bottom 5px
    padding-right 0
    display flex
    &.StatusDone
      background-color $color_primary_lightest
    &.StatusEquivalent
      background-color $color_purple_lightest
    &.StatusSkipped
      background-color $color_yellow_lightest
    &.StatusPostponed
      background-color $color_blue_lightest
    &.AutoAssessment
      background-color $color_yellow_lightest
    &.StatusExistingResult
      background-color $color_salmon_lightest

  .ImageWrap
    position relative
    flex-shrink 0
    box(65px)
    margin-right 5px
    .Number
      position absolute
      bottom 0
      width 100%
      flex-shrink 0
      margin-right 10px
      flex-direction column
      flex-center-children()
      padding 2.5px
      .StatusDone &
        background-color rgba($color_primary, 0.7)
      .StatusEquivalent &
        background-color rgba($color_purple, 0.8)
      .StatusSkipped &
        background-color rgba($color_yellow, 0.7)
      .StatusPostponed &
        background-color rgba($color_blue, 0.7)
      .StatusExistingResult &
        background-color rgba($color_salmon, 0.7)
      .AutoAssessment &
        background-color rgba($color_yellow, 0.7)
      span
        text-align center
        line-height 1
        display block
        font-size 0.75rem
        font-weight bold
        &.CaseNumber
          font-size 0.5rem
          line-height 1.5
    .KindOfIcon
      position absolute
      top 0
      right 0
      box(30px)
      padding 5px
      background-color rgba($color_blue_dark, 0.7)
      svg
        fill #fff

  .InfoWrap
    min-width 1px
    flex-grow 2
    span
      truncated()
      font-size 0.75rem
    .Title
      font-size 0.875rem
      text-transform uppercase
      color $color_grey_dark
    .Row
      display flex
      align-items center
      margin-bottom 2px
      &:last-child
        margin-bottom 0
      .IconWrap
        box(14px)
        flex-center-children()
        margin-right 5px
        flex-shrink 0
        svg
          fill $color_grey
          flex-shrink 0
        &.SmallerIcon
          padding 1px
      span
        padding-top 2px
        height 14px
        line-height 1
    .AssessText
      font-weight bold

  .FloorPlanButton
    display flex
    align-items center
    margin-bottom 0

  .UploadOptionsWrap
    display flex
    align-items center
    .UploadImageWrap
      position relative
      box(40px)
      flex-shrink 0
      margin-left 5px
      .Label
        pointer-events none
        position absolute
        bottom 0
        left 0
        width 100%
        font-size 0.665rem
        text-align center
        background-color rgba($color_grey_dark, 0.75)
        span
          color #fff

  .MenuWrap
    flex-center-children()
    flex-shrink 0
    width 40px
</style>

<style lang="stylus">
  .SampleItem
    .Row
      b
        line-height 1
        color $color_purple_dark
</style>
