<template>
  <div class="SelectFloorPlan">
    <ErrorText
      :text="mixWB('NO_FLOOR_PLANS_UPLOADED_MARKER')"
      v-if="!selectedFloorPlan" />
    <template v-else>
      <MultiImage
        v-if="selectedFloorPlan"
        :keepSquare="false"
        :image="selectedFloorPlan.floorPlan"
        transformation="default"
        @on-load="imageIsLoaded">
        <div
          v-if="imageLoaded"
          class="MarkerContainer"
          ref="container"
          v-on="{
            touchmove: handleDragMove,
            mousemove: handleDragMove,
            touchstart: handleDragStart,
            touchend: handleDragEnd,
            mousedown: handleDragStart,
            mouseup: handleDragEnd,
          }">
          <div
            class="Marker CurrentMarker"
            :class="{ IsDragging: isDragging }"
            v-on="{
            }"
            :style="getMarkerPositionStyle()">
            {{ sample.getNumber() || `P${ nextSampleNumber }` }}
          </div>

          <div
            v-for="otherSample in otherSamplePositions"
            :key="otherSample.id"
            class="Marker OtherMarker"
            :style="{
              top: `${ otherSample.floorPlanPosition.y }%`,
              left: `${ otherSample.floorPlanPosition.x }%`,
            }">
            {{ otherSample.getNumber() }}
          </div>
          <div
            class="MarkerPlaceholder"
            :class="{ Hidden: sampleMarkerPosition && sampleMarkerPosition.x !== null }" />
        </div>
      </MultiImage>
      <div
        class="Actions">
        <div
          v-if="availableFloorPlansOptions.length === 1"
          class="FloorSelectSingleOption">
          {{ availableFloorPlansOptions[0].name }}
        </div>
        <Dropdown
          v-else
          class="FloorSelect"
          name="selected-unit"
          :options="availableFloorPlansOptions"
          :value="selectedUnit"
          backgroundColor="white"
          @on-update="onSelectFloorPlanUnit" />
        <Button
          :text="mixWB('SAVE')"
          :isDisabled="!sampleMarkerPosition || !sampleMarkerPosition.x"
          @button-click="onSave" />
      </div>
    </template>
  </div>
</template>

<script>
import MultiImage from '@/components/Images/MultiImage.vue'
import Button from '@/components/Buttons/Button.vue'
import Dropdown from '@/components/FormElements/Dropdown.vue'
import { mapGetters } from 'vuex'
import ErrorText from '@/components/FormElements/ErrorText.vue'

const MARKER_WIDTH = 20

const percent = (val, max) => (val / max) * 100

export default {
  name: 'SelectFloorPlan',
  props: {
    type: {
      type: Object,
      required: true,
    },
    sample: {
      type: Object,
      required: true,
    },
    nextSampleNumber: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      sampleMarkerPosition: undefined,
      containerBounds: undefined,
      imageLoaded: false,
      isDragging: false,
      firstTouchPoint: undefined,
      dragDiffPosition: undefined,
      selectedUnit: undefined,
      checkForBoundsInterval: undefined,
    }
  },
  computed: {
    ...mapGetters([
      'screeningSamples',
    ]),
    availableFloorPlans() {
      return this.type.getAllFloorPlans()
    },
    availableFloorPlansOptions() {
      return this.availableFloorPlans.map((item) => ({
        value: item.id,
        name: item.name,
      }))
    },
    selectedFloorPlan() {
      if (this.selectedUnit) {
        return this.availableFloorPlans.find(
          (item) => item.id === this.selectedUnit,
        )
      }

      return false
    },
    otherSamplePositions() {
      return this.screeningSamples.filter(
        (sample) => sample.id !== this.sample.id
        && sample.floorPlanUnit === this.selectedUnit,
      )
    },
  },
  methods: {
    imageIsLoaded() {
      this.imageLoaded = true
      this.resetContainerBounds()
    },
    resetContainerBounds() {
      if (this.checkForBoundsInterval) {
        return
      }
      this.checkForBoundsInterval = setInterval(() => {
        if (!this.$refs.container) {
          return
        }
        const bounds = this.$refs.container.getBoundingClientRect()
        if (bounds.width && bounds.height) {
          clearInterval(this.checkForBoundsInterval)
          this.checkForBoundsInterval = undefined
          this.containerBounds = bounds
        }
      }, 200)
    },
    getMarkerPositionStyle() {
      const markerPosition = this.sampleMarkerPosition

      if (!markerPosition) {
        return {}
      }

      return {
        left: `${ markerPosition.x }%`,
        top: `${ markerPosition.y }%`,
      }
    },
    getPosition(event) {
      const diffX = this.dragDiffPosition?.x ?? 0
      const diffY = this.dragDiffPosition?.y ?? 0
      const pageX = (event.pageX || event.changedTouches[0].pageX) + diffX
      const pageY = (event.pageY || event.changedTouches[0].pageY) + diffY
      const pxPos = {
        x: Math.min(
          this.containerBounds.width - MARKER_WIDTH / 2,
          Math.max(pageX - this.containerBounds.x, MARKER_WIDTH / 2),
        ),
        y: Math.min(
          this.containerBounds.height - MARKER_WIDTH / 2,
          Math.max(pageY - this.containerBounds.y, MARKER_WIDTH / 2),
        ),
      }

      return {
        x: percent(pxPos.x, this.containerBounds.width),
        y: percent(pxPos.y, this.containerBounds.height),
      }
    },
    getDiffPosition(event) {
      if (!this.sampleMarkerPosition?.x) {
        return undefined
      }

      // Calculate the difference between the first touch point and current sampleMarkerPosisiton
      const pageX = event.pageX || event.changedTouches[0].pageX
      const pageY = event.pageY || event.changedTouches[0].pageY

      const containerX = pageX - this.containerBounds.x
      const containerY = pageY - this.containerBounds.y

      // Convert the markers percent value to pixel values before calculating the diff
      const markerPixelxPosX = (this.containerBounds.width * this.sampleMarkerPosition.x) / 100
      const markerPixelxPosY = (this.containerBounds.height * this.sampleMarkerPosition.y) / 100

      return {
        x: markerPixelxPosX - containerX,
        y: markerPixelxPosY - containerY,
      }
    },
    handleDragStart(e) {
      this.isDragging = true
      // We need to track the fidd posistion based on the first touch point,
      // but only assign it to our diff in `handleDragMove` where we are sure we are dragging.
      this.firstTouchPoint = this.getDiffPosition(e)
    },
    handleDragMove(e) {
      if (!this.isDragging) {
        return
      }

      // On the first drag move event, set the diff.
      if (!this.dragDiffPosition) {
        this.dragDiffPosition = this.firstTouchPoint
        this.firstTouchPoint = undefined
      }

      e.preventDefault()
      e.stopPropagation()
      this.sampleMarkerPosition = this.getPosition(e)
    },
    handleDragEnd(e) {
      this.isDragging = false
      this.sampleMarkerPosition = this.getPosition(e)
      this.dragDiffPosition = undefined
    },
    onSelectFloorPlanUnit({ value }) {
      this.selectedUnit = value

      if (this.sample.floorPlanUnit === value) {
        this.sampleMarkerPosition = this.sample.floorPlanPosition
      }
      else {
        this.sampleMarkerPosition = undefined
      }
    },
    onSave() {
      this.$emit('pass-to-parent', {
        name: 'floor-plan-select',
        value: {
          floorPlanPosition: this.sampleMarkerPosition,
          floorPlanUnit: this.selectedUnit,
        },
      })
    },
  },
  components: {
    MultiImage,
    Button,
    Dropdown,
    ErrorText,
  },
  created() {
    const availableUnitIDs = this.type.unitIDs

    if (this.sample.floorPlanUnit && availableUnitIDs.includes(this.sample.floorPlanUnit)) {
      this.selectedUnit = this.sample.floorPlanUnit
      if (this.sample.floorPlanPosition) {
        this.sampleMarkerPosition = this.sample.floorPlanPosition
      }
    }
    else {
      const firstRelevant = this.availableFloorPlans.find(
        (floorPlan) => availableUnitIDs.includes(floorPlan.id),
      )
      if (firstRelevant) {
        this.selectedUnit = firstRelevant.id
      }
      else {
        this.selectedUnit = this.availableFloorPlans[0]?.id ?? null
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.resetContainerBounds)
  },
  destroyed() {
    window.removeEventListener('resize', this.resetContainerBounds)
    if (this.checkForBoundsInterval) {
      clearInterval(this.checkForBoundsInterval)
      this.checkForBoundsInterval = undefined
    }
  },
}
</script>

<style lang="stylus" scoped>
  .SelectFloorPlan
    height 100%
    width 100%
    display flex
    flex-direction column
    justify-content center
    background black
    >>> .ErrorText
      color white
      text-align center

    .MultiImage
      user-select none
      display flex
      justify-content center
      align-items center
      >>> .Inner
        position relative
        max-height calc(100vh - 50px)
        max-width 60vh // Be sure that it will show in portrait mode. Also on desktop
        min-width 100px // Fixes that the loading progress would be weird.
        width auto
        height auto
        display inline-block
        img
          width initial
          max-height inherit

    >>> .MarkerContainer
      overflow hidden
      position absolute
      top 0
      bottom 0
      left 0
      right 0
    .MarkerPlaceholder
      width: 50px
      height: 50px
      background white
      position absolute
      bottom 0
      left 0
      transition none
      &.Hidden
        bottom -50px
        pointer-events none
        transition bottom 300ms ease-in-out
        transition-delay 200ms
    .Marker
      position absolute
      top: calc(100% - 25px)
      left: 25px
      display flex
      box($markerWidth = 25px)
      align-items center
      justify-content center
      border-radius: 50%
      background $color_primary
      margin-left -($markerWidth / 2)
      margin-top -($markerWidth / 2)
      cursor pointer
      color white
      font-size 0.75rem
      line-height 1
      &.IsDragging
        cursor grabbing
    .CurrentMarker
      z-index 1
      border 1px solid rgba(255, 255, 255, 0.8)
      &:before
        // Touch target minimum 40px
        content ""
        padding: 20px
        position absolute
    .OtherMarker
      opacity .5
    .Actions
      display flex
      height: 70px
      .FloorSelect >>> .SelectWrap, .FloorSelect >>> select, .FloorSelectSingleOption, .Button
        height: 100%
      .FloorSelect, .FloorSelectSingleOption
        width 100%
        margin-bottom 0
        background white
      .FloorSelectSingleOption
        display flex
        padding-left 11px
        font-size 1.125rem
        align-items center
      >>> .ButtonWrap
        width 100%
        margin-bottom 0
        .Button
          height 100%
</style>
