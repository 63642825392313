<template>
  <div
    :class="{
      'NotDone': sample.status === 'not-done',
      'StatusDone': sample.status === 'done',
      'StatusEquivalent': sample.status === 'equivalent',
      'StatusExistingResult': sample.status === 'existing-result',
      'StatusSkipped': sample.status.includes('skip-'),
      'StatusPostponed': sample.status.includes('postpone-sample'),
    }"
    class="SampleItemMenu"
    @click.stop.prevent>
    <!-- Button -->
    <div
      class="Button"
      @click="onButtonClick">
      <CheckIcon v-if="sample.status !== 'not-done'" />
      <Dots v-else />
    </div>

    <!-- Backdrop -->
    <div
      v-if="isShowing"
      class="BackDrop"
      @click="onBackDropClick"/>

    <!-- Menu -->
    <div
      v-if="isShowing"
      class="MenuWrap">
      <DotMenuItem
        :text="`${ mixWB('SAME_AS') } ...`"
        itemID="equivalent"
        @dot-item-click="onDotItemClick" />
      <DotMenuItem
        :text="`${ mixWB('ASSESS') }`"
        itemID="assess"
        :isDisabled="sample.kindOfSample === 'dust'"
        @dot-item-click="onDotItemClick" />
      <DotMenuItem
        :text="`${ mixWB('POSTPONE_SAMPLE') }`"
        itemID="postpone"
        @dot-item-click="onDotItemClick" />
      <DotMenuItem
        :text="`${ mixWB('ENTER_RESULT') }`"
        :isDisabled="isEnterResultOptionDisabled"
        itemID="enter-result"
        @dot-item-click="onDotItemClick" />
      <DotMenuItem
        :text="`${ mixWB('SAMPLE_MARK_FLOOR_PLAN') }`"
        :isDisabled="!canMarkOnFLoorPlan"
        itemID="set-marker-floorplan"
        @dot-item-click="onDotItemClick" />
      <DotSplitLine />
      <DotMenuItem
        :text="mixWB('ADD_ANALYSES')"
        itemID="add-samples"
        :isDisabled="isAddManualSamplesDisabled"
        @dot-item-click="onDotItemClick" />
      <DotSplitLine />
      <DotMenuItem
        :text="mixWB('RESET')"
        itemID="reset"
        color="red"
        @dot-item-click="onDotItemClick" />
    </div>
  </div>
</template>

<script>
import CheckIcon from '@/assets/svg/check.svg?inline'
import DotMenuItem from '@/components/DotMenu/DotMenuItem.vue'
import DotSplitLine from '@/components/DotMenu/DotSplitLine.vue'
import Dots from '@/components/DotMenu/Dots.vue'

export default {
  name: 'SampleItemMenu',
  props: {
    type: {
      type: Object,
      required: true,
    },
    sample: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isShowing: false,
    }
  },
  computed: {
    canMarkOnFLoorPlan() {
      return this.sample.status !== 'equivalent'
        && !this.sample.isAutoAssessment
        && !this.sample.status.includes('skip-')
        && this.sample.images.close && this.sample.images.away
    },
    isAddManualSamplesDisabled() {
      if (this.sample.kindOfSample === 'material' && this.type.type.customTypeName) {
        return true
      }

      return this.sample.status.includes('skip-') || this.sample.isAutoAssessment
    },
    isEnterResultOptionDisabled() {
      return !['not-done', 'existing-result'].includes(this.sample.status)
    },
  },
  methods: {
    onBackDropClick() {
      this.isShowing = !this.isShowing
    },
    onButtonClick() {
      this.isShowing = !this.isShowing
    },
    onDotItemClick(itemID) {
      this.$emit('option-click', itemID)

      this.isShowing = !this.isShowing
    },
  },
  components: {
    CheckIcon,
    DotMenuItem,
    DotSplitLine,
    Dots,
  },
}
</script>

<style lang="stylus" scoped>
  .SampleItemMenu
    position relative
    box(100%)
    &.NotDone
      .Button
        padding 25%
      svg
        padding-left 2px
        transform rotateZ(90deg)
    &.StatusDone
      .Button
        padding 20%
      svg
        fill $color_primary
    &.StatusEquivalent
      .Button
        padding 20%
      svg
        fill $color_purple_dark
    &.StatusSkipped
      .Button
        padding 20%
      svg
        fill $color_yellow_darkest
    &.StatusPostponed
      .Button
        padding 20%
      svg
        fill $color_blue_darkest
    &.StatusExistingResult
      .Button
        padding 20%
      svg
        fill $color_salmon_dark

  .Button
    box(100%)
    flex-center-children()
    svg
      box(100%)

  .BackDrop
    position fixed
    top 0
    left 0
    width 100vw
    height 100vh
    background-color rgba(255, 255, 255, 0.4)
    z-index 1

  .MenuWrap
    position absolute
    z-index 2
    top 50%
    right 50%
    min-width 200px
    background-color #fff
    border 1px solid $color_grey_light

</style>
