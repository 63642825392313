<template>
  <div class="ScreeningsUpdateSamples">
    <div class="InnerPage">
      <CloseButton />

      <div class="TitleWrap">
        <span class="PageTitle">
          {{ mixWB('SAMPLING') }} ({{ this.samplesStatus.done }}/{{ this.samplesStatus.total }})
        </span>
        <SingleUnitSelectedText />
      </div>

      <NextStepLine
        :isDisabled="!samplesStatus.isCompleted"
        showTopBorder />

      <div class="OptionsWrap">
        <ToggleButton
          groupName="missing-samples-toggle"
          value="missing-samples-toggle"
          :isSelected="completedSamplesToggle"
          :text="mixWB('HIDE_DONE_SAMPLES_TOGGLE_TEXT')"
          @check="onToggleShowSamples" />
      </div>

      <div class="ListWrap">
        <div
          v-for="item in listOfSamples"
          :key="item.category.id">
          <!-- Area title -->
          <span
            class="AreaTitle"
            v-if="item.areaTitle">{{ item.areaTitle }}</span>

          <!-- Category section -->
          <div
            class="CategorySection"
            :key="item.category.id">
            <span class="CategoryTitle">{{ mixWB(item.categoryTitle) }}</span>
            <div class="MissingSamples">
              <SampleItem
                v-for="(item, index) in item.missingSamples"
                :key="item.sample.id"
                :type="item.type"
                :sample="item.sample"
                :sampleIndex="index + 1"
                :nextSampleNumber="nextSampleNumber"
                @floor-plan-selected="onFloorPlanSelected"
                @new-state-selected="onNewStateSelected"
                @new-image-selected="onNewImageSelected" />
            </div>
            <div v-show="item.completedSamples.length">
              <!-- Header -->
              <div
                class="DoneListHeader"
                :class="{
                  IsOpen: showCompletedSamples[item.category.id],
                }"
                @click="onToggleDoneTypesClick(item.category.id)">
                <div
                  class="CheckIconWrap"
                  v-if="!item.missingSamples.length">
                  <CheckIcon />
                </div>
                <span v-if="!item.missingSamples.length">
                  {{ mixWB('ALL_SAMPLES_ARE_DONE') }}</span>
                <span v-else-if="item.completedSamples.length === 1">
                  {{ mixWB('1_SAMPLE_IS_DONE') }}</span>
                <span v-else>
                  {{ mixWB('X_SAMPLES_ARE_DONE', [item.completedSamples.length]) }}</span>
                <div class="IconWrap">
                  <AngleRightIcon />
                </div>
              </div>
              <div
                class="DoneListBody"
                v-show="showCompletedSamples[item.category.id]">
                <SampleItem
                  v-for="(item, index) in item.completedSamples"
                  :key="item.sample.id"
                  :type="item.type"
                  :sample="item.sample"
                  :sampleIndex="index + 1"
                  :nextSampleNumber="nextSampleNumber"
                  @floor-plan-selected="onFloorPlanSelected"
                  @new-state-selected="onNewStateSelected"
                  @new-image-selected="onNewImageSelected" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import CloseButton from '@/components/ScreeningItems/CloseButton.vue'
import SingleUnitSelectedText from '@/components/SingleUnitSelectedText.vue'
import { mapGetters } from 'vuex'
import Sample from '@/globals/javascript/models/Sample'
import SampleItem from '@/components/SampleItems/SampleItem.vue'
import NextStepLine from '@/components/ScreeningItems/NextStepLine.vue'
import ToggleButton from '@/components/FormElements/ToggleButton.vue'
import CheckIcon from '@/assets/svg/check.svg?inline'
import AngleRightIcon from '@/assets/svg/angle-right.svg?inline'
import { sortBy } from 'lodash-es'

export default {
  name: 'ScreeningsUpdateSamples',
  data() {
    return {
      completedSamplesToggle: true,
      showCompletedSamples: {},
    }
  },
  computed: {
    ...mapGetters([
      'areasAsObject',
      'categoriesAsArray',
      'currentScreeningSelectedFilterUnitID',
      'samplesStatus',
      'screeningSamples',
      'typesWithSamples',
      'nextAssessNumber',
      'screeningRelatedScreenings',
    ]),
    nextSampleNumber() {
      const sampleNumbers = this.screeningSamples.reduce((prev, sample) => {
        if (sample.sampleNumber) {
          prev.push(sample.sampleNumber)
        }
        return prev
      }, []).sort((a, b) => a - b)

      if (!sampleNumbers.length) {
        return 1
      }

      let nextNumber = null
      sampleNumbers.forEach((number, index) => {
        if (nextNumber) {
          return
        }
        if (number !== index + 1) {
          nextNumber = index + 1
        }
      })

      return nextNumber || sampleNumbers[sampleNumbers.length - 1] + 1
    },
    nextEquivalentNumber() {
      const equivalentNumbers = this.screeningSamples.reduce((prev, sample) => {
        if (sample.equivalentNumber) {
          prev.push(sample.equivalentNumber)
        }
        return prev
      }, []).sort((a, b) => a - b)

      if (!equivalentNumbers.length) {
        return 1
      }

      let nextNumber = null
      equivalentNumbers.forEach((number, index) => {
        if (nextNumber) {
          return
        }
        if (number !== index + 1) {
          nextNumber = index + 1
        }
      })

      return nextNumber || equivalentNumbers[equivalentNumbers.length - 1] + 1
    },
    nextPostponeNumber() {
      const postponeNumberes = this.screeningSamples.reduce((prev, sample) => {
        if (sample.postponeNumber) {
          prev.push(sample.postponeNumber)
        }
        return prev
      }, []).sort((a, b) => a - b)

      if (!postponeNumberes.length) {
        return 1
      }

      let nextNumber = null
      postponeNumberes.forEach((number, index) => {
        if (nextNumber) {
          return
        }
        if (number !== index + 1) {
          nextNumber = index + 1
        }
      })

      return nextNumber || postponeNumberes[postponeNumberes.length - 1] + 1
    },
    nextExistingResultNumber() {
      const existingResultNumberes = this.screeningSamples.reduce((prev, sample) => {
        if (sample.existingResultNumber) {
          prev.push(sample.existingResultNumber)
        }
        return prev
      }, []).sort((a, b) => a - b)

      if (!existingResultNumberes.length) {
        return 1
      }

      let nextNumber = null
      existingResultNumberes.forEach((number, index) => {
        if (nextNumber) {
          return
        }
        if (number !== index + 1) {
          nextNumber = index + 1
        }
      })

      return nextNumber || existingResultNumberes[existingResultNumberes.length - 1] + 1
    },
    listOfSamples() {
      const list = []

      this.categoriesAsArray.forEach((category) => {
        const categoryTypes = this.typesWithSamples.filter((x) => x.categoryID === category.id)

        // Find category types
        let types = []
        if (this.currentScreeningSelectedFilterUnitID) {
          types = categoryTypes.filter(
            (x) => x.unitIDs.includes(this.currentScreeningSelectedFilterUnitID),
          )
        }
        else {
          types = categoryTypes
        }

        if (!types.length) {
          return
        }

        const item = {
          category,
          areaTitle: '',
          categoryTitle: '',
          samples: [],
          completedSamples: [],
          missingSamples: [],
        }

        // Check to set area title
        const lastListItem = list[list.length - 1]
        if (!lastListItem || lastListItem?.category?.areaID !== category.areaID) {
          item.areaTitle = this.mixWB(this.areasAsObject[category.areaID].translation)
        }

        // Get samples for each type
        types.forEach((type) => {
          // Material auto-assessment sample
          if (type.assessments.sampleIDs.length) {
            const existingMaterialSample = this.screeningSamples.find(
              (x) => x.typeID === type.id && x.kindOfSample === 'material',
            )

            if (!existingMaterialSample) {
              return
            }

            item.samples.push({
              type,
              sample: existingMaterialSample,
              categoryPosition: 0,
            })
          }

          // Material sample
          const materialSampleIDs = type.getMaterialSampleList({ onlyIDs: true })
          if (materialSampleIDs.length) {
            let sample = null
            const existingMaterialSample = this.screeningSamples.find(
              (x) => x.typeID === type.id && x.kindOfSample === 'material',
            )
            if (existingMaterialSample) {
              sample = existingMaterialSample
            }
            else {
              sample = new Sample({
                typeID: type.id,
                kindOfSample: 'material',
              })
            }
            item.samples.push({
              type,
              sample,
              categoryPosition: category.position,
            })
          }

          // Coating sample
          const coatingSampleIDs = type.getCoatingSampleList({ onlyIDs: true })
          if (coatingSampleIDs.length) {
            let sample = null
            const existingCoatingSample = this.screeningSamples.find(
              (x) => x.typeID === type.id && x.kindOfSample === 'coating',
            )
            if (existingCoatingSample) {
              sample = existingCoatingSample
            }
            else {
              sample = new Sample({
                typeID: type.id,
                kindOfSample: 'coating',
              })
            }
            item.samples.push({
              type,
              sample,
              categoryPosition: category.position,
            })
          }

          // Dust sample
          const dustSampleIDs = type.getDustSampleList({ onlyIDs: true })
          if (dustSampleIDs.length) {
            let sample = null
            const existingDustSample = this.screeningSamples.find(
              (x) => x.typeID === type.id && x.kindOfSample === 'dust',
            )
            if (existingDustSample) {
              sample = existingDustSample
            }
            else {
              sample = new Sample({
                typeID: type.id,
                kindOfSample: 'dust',
              })
            }
            item.samples.push({
              type,
              sample,
              categoryPosition: category.position,
            })
          }
        })

        // Set category title
        item.categoryTitle = `${
          this.mixWB(category.translation)
        } (${
          item.samples.length
        })`

        // Sort samples
        item.samples = sortBy(item.samples, [
          (x) => x.sample.getFullTitle(),
          (x) => x.sample.getNumber() || '',
        ])

        // Split samples into completed and missing
        item.samples.forEach((sample) => {
          if (sample.sample.status === 'not-done') {
            item.missingSamples.push(sample)
          }
          else {
            item.completedSamples.push(sample)
          }
        })

        list.push(item)
      })

      return list
    },
  },
  watch: {
    listOfSamples() {
      this.updateShowCompletedSamples()
    },
  },
  methods: {
    onToggleShowSamples() {
      this.completedSamplesToggle = !this.completedSamplesToggle

      Object.keys(this.showCompletedSamples).forEach((categoryID) => {
        this.showCompletedSamples[categoryID] = !this.completedSamplesToggle
      })
    },
    onToggleDoneTypesClick(categoryID) {
      this.showCompletedSamples[categoryID] = !this.showCompletedSamples[categoryID]
    },
    updateShowCompletedSamples() {
      this.listOfSamples.forEach((item) => {
        if (this.showCompletedSamples.hasOwnProperty(item.category.id)) {
          return
        }

        Vue.set(this.showCompletedSamples, item.category.id, !this.completedSamplesToggle)
      })
    },
    onNewStateSelected({
      sample, status, equivalentSampleID, otherScreeningID, wdcID, explanation,
    }) {
      setTimeout(() => {
        this.saveSample({
          sample,
          status,
          equivalentSampleID,
          otherScreeningID,
          wdcID,
          explanation,
        })
      }, equivalentSampleID ? 100 : 0)
    },
    onNewImageSelected({
      sample, imageData, type, equivalentSampleID,
    }) {
      this.saveSample({
        sample,
        closeImage: type === 'close' ? imageData : false,
        awayImage: type === 'away' ? imageData : false,
        equivalentSampleID,
      })
    },
    onFloorPlanSelected({
      sample,
      floorPlanPosition,
      floorPlanUnit,
    }) {
      this.saveSample({
        sample,
        floorPlanUnit,
        floorPlanPosition,
      })
    },
    saveSample({
      sample,
      status,
      closeImage,
      awayImage,
      equivalentSampleID,
      floorPlanUnit,
      floorPlanPosition,
      otherScreeningID = '',
      wdcID,
      explanation,
    }) {
      const isNew = !!this.screeningSamples.find((x) => x.id === sample.id)

      if (status) {
        sample.status = status
      }

      if (status === 'not-done') {
        sample.reset()
        this.$store.dispatch('deleteScreeningSample', sample)
        return
      }

      if (sample.status === 'equivalent') {
        let otherSample = null
        if (otherScreeningID) {
          const relatedScreening = this.screeningRelatedScreenings.find(
            (x) => x.data.id === otherScreeningID,
          )
          otherSample = relatedScreening.samples.find((x) => x.id === equivalentSampleID)
        }
        else {
          otherSample = this.screeningSamples.find((x) => x.id === equivalentSampleID)
        }

        const equivalentNumber = sample.equivalentNumber
          && sample.equivalentNumber < this.nextEquivalentNumber
          ? sample.equivalentNumber
          : this.nextEquivalentNumber
        sample.reset(status)
        sample.otherScreeningID = otherScreeningID
        sample.equivalentNumber = equivalentNumber
        sample.equivalentSampleID = equivalentSampleID
        sample.equivalentSampleNumber = otherSample.sampleNumber
        this.$store.dispatch('setScreeningSample', { sample, isNew })
        return
      }

      if (sample.status.includes('skip-')) {
        const assessNumber = sample.assessNumber && sample.assessNumber < this.nextAssessNumber
          ? sample.assessNumber
          : this.nextAssessNumber
        sample.reset(status)
        sample.otherScreeningID = otherScreeningID
        sample.assessNumber = assessNumber
        this.$store.dispatch('setScreeningSample', { sample, isNew })
        return
      }

      if (sample.status === 'postpone-sample') {
        const postponeNumber = sample.postponeNumber
          && sample.postponeNumber < this.nextpostponeNumber
          ? sample.postponeNumber
          : this.nextPostponeNumber

        sample.reset(status)
        sample.postponeNumber = postponeNumber
        this.$store.dispatch('setScreeningSample', { sample, isNew })
        return
      }

      if (sample.status === 'existing-result') {
        const existingResultNumber = sample.existingResultNumber
          && sample.existingResultNumber < this.nextExistingResultNumber
          ? sample.existingResultNumber
          : this.nextExistingResultNumber
        sample.reset(status)
        sample.existingResultNumber = existingResultNumber
        sample.existingResultWDCID = wdcID
        sample.existingResultExplanation = explanation
        this.$store.dispatch('setScreeningSample', { sample, isNew })
        return
      }

      sample.otherScreeningID = otherScreeningID

      const setDone = () => {
        if (sample.images.close
          && sample.images.away
          && sample.floorPlanUnit
          && sample.floorPlanPosition) {
          sample.status = 'done'
        }
      }

      if (closeImage) {
        sample.images.close = closeImage
        setDone()
      }

      if (awayImage) {
        sample.images.away = awayImage
        setDone()
      }

      if (floorPlanPosition && floorPlanUnit) {
        sample.floorPlanPosition = floorPlanPosition
        sample.floorPlanUnit = floorPlanUnit
        setDone()
      }

      if (sample.status === 'done') {
        sample.assessNumber = null
        sample.equivalentNumber = null
        sample.equivalentSampleID = null
        sample.equivalentSampleNumber = null

        if (!sample.sampleNumber) {
          sample.sampleNumber = this.nextSampleNumber
        }
      }

      this.$store.dispatch('setScreeningSample', { sample, isNew })
    },
  },
  components: {
    CloseButton,
    SingleUnitSelectedText,
    SampleItem,
    NextStepLine,
    ToggleButton,
    CheckIcon,
    AngleRightIcon,
  },
  created() {
    this.updateShowCompletedSamples()
  },
}
</script>

<style lang="stylus" scoped>
  .ScreeningsUpdateSamples
    page-wrap-outer-1()

  .InnerPage
    page-wrap-inner-1()

  .TitleWrap
    margin-bottom 20px
    .PageTitle
      display block
      font-size 1.125rem
      font-weight bold
      text-transform uppercase

  .OptionsWrap
    background-color $color_grey_lightest
    padding 20px 15px
    margin-bottom 20px

  .AreaTitle
    margin-bottom 7.5px
    font-weight bold
    text-transform uppercase
    border-bottom 1px solid $color_grey_lighter

  .CategorySection
    margin-bottom 15px

  .CategoryTitle
    margin-bottom 5px
    font-size 0.875rem
    text-transform uppercase

  .MissingSamples
    >>> .SampleItem
      margin-bottom 5px

  .DoneListHeader
    position relative
    display flex
    background-color $color_grey_lightest
    padding 5px
    padding-right 7.5px
    .CheckIconWrap
      box(12px)
      margin-right 5px
      svg
        fill $color_primary
    span
      color $color_grey
      font-size 0.75em
    .IconWrap
      position absolute
      top -7.5px
      right -7.5px
      z-index 1
      box(40px)
      padding 15px
      flex-center-children()
      transform rotateZ(-90deg)
      cursor pointer
      margin-top -1px
      svg
        box(100%)
    &.IsOpen
      .IconWrap
        transform rotateZ(90deg)
        margin-top 0px

  .DoneListBody
    border-top 1px solid $color_grey_lighter
    padding 5px
    background-color $color_grey_lightest
</style>
