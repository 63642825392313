<template>
  <div class="SelectResult">
    <span class="Title">{{ mixWB('ENTER_RESULT') }}</span>

    <p class="Explanation">
      {{ mixWB('ENTER_RESULT_INFO_MODAL_TEXT') }}
    </p>

    <!-- Options -->
    <div class="Section">
      <span class="SectionTitle">{{ mixWB('DEGREE_OF_CONTAMINATION') }}</span>
      <div class="ListWrap">
        <div
          class="Option"
          :class="{ IsSelected: item.id === wdcID }"
          v-for="item in workDescriptionCasesAsArray"
          :key="item.id"
          @click="onOptionClick(item.id)">{{ mixWB(item.translation) }}</div>
      </div>
    </div>

    <!-- Text -->
    <div class="Section">
      <span class="SectionTitle">{{ mixWB('REFERENCE') }}</span>
      <InputField
        name="explanation"
        :value="explanation"
        :placeholder="mixWB('EXPLANATION_PLACEHOLDER_TEXT')"
        @on-update="onExplanationUpdate" />
      <span class="InfoText">
        {{ currentScreeningData.getCanBeFurtherExplainedInCommentsText() }}
      </span>
    </div>

    <!-- Save button -->
    <div class="ButtonsWrap">
      <Button
        :text="mixWB('SAVE')"
        :fullWidth="false"
        :isDisabled="isButtonDisabled"
        @button-click="onSaveClick" />
    </div>
  </div>
</template>

<script>
import InputField from '@/components/FormElements/InputField.vue'
import { mapGetters } from 'vuex'
import Button from '@/components/Buttons/Button.vue'

export default {
  name: 'SelectResult',
  props: {
    sample: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      wdcID: '',
      explanation: '',
    }
  },
  computed: {
    ...mapGetters([
      'workDescriptionCasesAsArray',
      'currentScreeningData',
    ]),
    isButtonDisabled() {
      return !this.wdcID || !this.explanation
    },
  },
  methods: {
    setDataOnLoad() {
      this.wdcID = this.sample.existingResultWDCID || ''
      this.explanation = this.sample.existingResultExplanation || ''
    },
    onOptionClick(wdcID) {
      this.wdcID = wdcID
    },
    onExplanationUpdate({ value }) {
      this.explanation = value
    },
    onSaveClick() {
      const value = {
        wdcID: this.wdcID,
        explanation: this.explanation,
      }
      this.$emit('pass-to-parent', { name: 'result-select', value })
    },
  },
  components: {
    InputField,
    Button,
  },
  created() {
    this.setDataOnLoad()
  },
}
</script>

<style lang="stylus" scoped>
  .SelectResult
    display block

  .Title
    modalTitle()

  .Explanation
    color $color_grey
    padding-bottom 15px

  .Section
    margin-bottom 15px
    .SectionTitle
      display block
      margin-bottom 10px
      border-bottom 1px solid $color_grey_lighter
      font-size 0.875rem
      text-transform uppercase
      padding-bottom 3px
    >>> .InputField
      margin-bottom 5px
    .InfoText
      font-size 0.875rem
      color $color_grey

  .ListWrap
    display flex
    flex-wrap wrap
    .Option
      margin-right 5px
      margin-bottom 5px
      padding 5px
      text-transform uppercase
      font-size 0.875rem
      border 1px solid $color_grey_light
      cursor pointer
      &.IsSelected
        background-color $color_salmon
        border 1px solid $color_salmon
        color #fff

  .ButtonsWrap
    display flex
    justify-content flex-end
    >>> .ButtonWrap
      .Button
        min-width 100px
</style>
